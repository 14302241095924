import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstance from 'network/apis/index'

export const placeBetHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'game/fast-game/hilo/place-bet', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const demoPlaceBetHiLoService = (data) => {
  
  return axiosInstance(METHOD_TYPES.post, 'game/fast-game/hilo/demo-place-bet', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const openCardHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'game/fast-game/hilo/open-card', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const openDemoCardHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'game/fast-game/hilo/demo-open-card', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const cashOutBetHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'game/fast-game/hilo/cash-out-bet', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const demoCashOutBetHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'game/fast-game/hilo/demo-cash-out-bet', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const myBetsHiLoService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'game/fast-game/hilo/my-bets', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}

export const getUnfinishedBetService = () => {
  return axiosInstance(METHOD_TYPES.get, 'game/fast-game/hilo/unfinished-bet', {
    params: {},
    server: microServices.SERVICE_URL_1
  })
}
