import { getPrecisionNumber } from 'helpers/common.helpers'
import { MAX_MINE_COUNT, MAX_TILE_COUNT } from '../mineGame.constants'

export const calculateRTPOdds = (odds, gameSettings) => {
  return getPrecisionNumber(Math.max(gameSettings.minOdds, Math.min(gameSettings.maxOdds, odds * (1 - gameSettings.houseEdge / 100))))
}

export const calculateMineGameNextPayoutOdds = (mineCount, openedTileCount, gameSettings) => {
  const _nextTileOdds = (1 / ((MAX_TILE_COUNT - mineCount) / (MAX_TILE_COUNT)))
  let _cashoutOdds = 1

  if (openedTileCount > 0) {
    for (let index = 0; index < openedTileCount; index++) {
      const probability = 1 / ((MAX_TILE_COUNT - mineCount - index) / (MAX_TILE_COUNT - index))
      _cashoutOdds *= probability
    }
  }

  return calculateRTPOdds(_cashoutOdds * _nextTileOdds, gameSettings)
}

export const calculateMineGameCashoutOdds = (mineCount, openedTileCount, gameSettings) => {
  let combinedOdds = 1

  for (let index = 0; index < openedTileCount; index++) {
    const probability = 1 / ((MAX_TILE_COUNT - mineCount - index) / (MAX_TILE_COUNT - index))
    combinedOdds *= probability
  }

  return calculateRTPOdds(combinedOdds, gameSettings)
}

export const mines = Array.from({ length: MAX_MINE_COUNT }, (_, i) => i + 1)

export const generateRandomResult = (mineCount) => {
  // list of number of tiles in mine game
  const mines = Array.from({ length: 25 }, (_, index) => index + 1)
  // shuffle list
  for (let i = mines.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [mines[i], mines[j]] = [mines[j], mines[i]]
  }

  return mines.slice(0, mineCount)
}
