import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  demoPlaceBetPlinkoGameService,
  getMyBetsPlinkoService, placeBetPlinkoGameService,
  postLightningBoardDetailsService
} from 'games/PlinkoGame/plinkoGame.service'
import {
  fastModeBox, pixiCancelBall, pixiCreateBall, pixiStartMovingBall
} from 'games/PlinkoGame/pixi-js-scripts/bridge'
import { appendCreditDemoWalletWaitQueue, updateCreditDemoWalletWaitQueue, updateCreditWalletWaitQueue } from 'redux-store/redux/slices/auth.slice'
import { setNewClientSeed, setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { appendMyBetsPlinko, setBetLock } from 'games/PlinkoGame/slice-thunk/plinkoGame.slice'
import { generateClientSeed } from 'helpers/common.helpers'

export const placedBetPlinkoGame = createAsyncThunk('plinko-game/place-bet',
  async ({ payload, fastMode, isInfiniteBet, onSuccess, cb }, thunkApi) => {
    try {
      const newClientSeed = generateClientSeed()
      const res = await placeBetPlinkoGameService({ ...payload, clientSeed: newClientSeed })
      if (res?.nextServerSeedHash) {
        thunkApi.dispatch(setNewClientSeed(newClientSeed))
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }
      if (fastMode) {
        const dropDetails = res.dropDetails
        for (let i = 0; i < dropDetails.length; i++) {
          setTimeout(() => {
            fastModeBox(dropDetails[i])
          }, 75 * i)
        }

        thunkApi.dispatch(setBetLock())
        thunkApi.dispatch(appendMyBetsPlinko())
      } else {
        const dropDetails = res.dropDetails
        for (let i = 0; i < dropDetails.length; i++) {
          const lastBall = (i + 1) === dropDetails.length
          setTimeout(() => {
            pixiStartMovingBall(dropDetails[i])
            pixiCreateBall(lastBall)
          }, 75 * i)
        }
        // dropDetails.forEach(detail => {
        //   pixiStartMovingBall(detail)
        //   pixiCreateBall()
        // })
      }
      
      cb && cb()
      onSuccess && onSuccess({ isInfiniteBet })
      return res
    } catch (error) {
      if (!fastMode) {
        pixiCancelBall()
      }
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const demoPlacedBetPlinkoGame = createAsyncThunk('plinko-game/demo-place-bet',
  async ({ payload, fastMode }, thunkApi) => {
    const { cb } = payload;
    try {
      const res = await demoPlaceBetPlinkoGameService(payload)
      const newDemoWallet = {
        currency: payload?.currencyCode,
        balance: res?.demoBalance
      }
      thunkApi.dispatch(appendCreditDemoWalletWaitQueue(newDemoWallet))
      thunkApi.dispatch(updateCreditDemoWalletWaitQueue())
      if (fastMode) {
        const dropDetails = res.dropDetails
        for (let i = 0; i < dropDetails.length; i++) {
          setTimeout(() => {
            fastModeBox(dropDetails[i])
          }, 75 * i)
        }
        thunkApi.dispatch(setBetLock())
        // thunkApi.dispatch(updateCreditDemoWalletWaitQueue())
        thunkApi.dispatch(appendMyBetsPlinko())
      } else {
        const dropDetails = res.dropDetails
        for (let i = 0; i < dropDetails.length; i++) {
          const lastBall = (i + 1) === dropDetails.length
          setTimeout(() => {
            pixiStartMovingBall(dropDetails[i])
            pixiCreateBall(lastBall)
          }, 75 * i)
        }
      }

      cb && cb()
      return res
    } catch (error) {
      if (!fastMode) {
        pixiCancelBall()
      }
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsPlinko = createAsyncThunk('plinko-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsPlinkoService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const postLightningBoardDetails = createAsyncThunk('plinko-game/post-lightning-board-details', async (params, thunkApi) => {
  try {
    const res = await postLightningBoardDetailsService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0]?.description)
  }
})
