import { SYSTEM_GAME_SETTINGS_UPDATE } from 'socket-resources/event-constants/common-events'
import { SYSTEM } from 'socket-resources/namespaces/index'
import { io } from 'socket.io-client'

const useGameSettingsUpdateSocketNameSpace = () => {
  const listenGameSettingsUpdate = (cb) => {
    const gameSettingsUpdateInstance = io(`${process.env.REACT_APP_BACKEND_URL_WS}${SYSTEM}`, {
      transport: ['polling']
    })
    if (cb) {
      gameSettingsUpdateInstance.on(SYSTEM_GAME_SETTINGS_UPDATE, cb)
    }
    return () => {
      gameSettingsUpdateInstance.off(SYSTEM_GAME_SETTINGS_UPDATE, cb)
    }
  }

  return listenGameSettingsUpdate
}

export default useGameSettingsUpdateSocketNameSpace
